import React from "react"
import tw from "twin.macro"
import Tagline from "./Tagline"
import Headline from "./Headline"
import Description from "./Description"

const BannerLayout = tw.section`
    pt-12 px-8 md:px-20 xl:px-32
    min-h-screen w-full
    flex flex-col justify-center text-base md:text-lg`

export default function Banner({ className, tagLine, headline, shortDescription }) {
  return (
      <BannerLayout className={className}>
        <Tagline>{tagLine}</Tagline>
        <Headline>{headline}</Headline>
        { shortDescription && <Description>{ shortDescription }</Description> }
      </BannerLayout>
  )
}