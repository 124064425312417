import React, { useState } from "react"
import { LanguagesDropdown } from "./LanguagesDropdown"
import { Link } from "gatsby"
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"
import { RichText } from "prismic-reactjs"
import tw from "twin.macro"
import "../assets/styles/global.css"
import "lazysizes"
import "lazysizes/plugins/respimg/ls.respimg"
import "lazysizes/plugins/blur-up/ls.blur-up"
import Logotype from "./Logotype"

const Container = tw.div`font-sans`
const Header = tw.header`
  w-screen items-center justify-between flex-wrap fixed
  px-0 bg-white
  md:px-4 md:p-6 md:py-0 flex z-50 shadow
`
const Logo = tw.span`
  p-4 md:p-6 md:py-0
  flex items-center flex-shrink-0
`
const Nav = tw.nav`
  pb-3 md:pb-0
  w-full md:flex md:flex-row 
  md:items-center md:w-auto
`
const Lang = tw.div`hidden md:block`
const NavItem = tw.span`
  py-6 px-6
  block md:inline-block md:mt-0 md:flex-grow
  hover:bg-gray-25
`
const SmMenu = tw.div`md:hidden flex text-sm mt-1 px-2 md:p-6 md:py-0`
const MenuButton = tw.button`
  p-2 mr-2 mb-1 appearance-none focus:outline-none
  block
`

const Layout = ({ activePageMeta, activeSubPageMeta, navOptions, children }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  if (!activeSubPageMeta) {
    activeSubPageMeta = activePageMeta
  }
  return (
    <Container>
      <Header>
        <Logo>
          <Link to={linkResolver({ ...activePageMeta, type: "homepage" })}
                aria-label="Tropical Building Physics">
            <Logotype className="h-8 md:h-12"/>
          </Link>
        </Logo>
        <SmMenu>
          <MenuButton onClick={() => toggleExpansion(!isExpanded)}>
            MENU <i className={`${!isExpanded ? "fal fa-angle-down" : "fal fa-angle-up"}`}/>
          </MenuButton>
          <LanguagesDropdown activePageMeta={activePageMeta}/>
        </SmMenu>
        <Nav className={`${!isExpanded ? "hidden" : "block"}`}>
          {
            navOptions.map((option, index) => (
              <Link to={linkResolver(option.link._meta)}
                    key={`link-${index}`}>
                <NavItem className={activePageMeta.uid === option.link._meta.uid && "bg-gray-25"}
                         key={`nav-${index}`}>
                  {RichText.asText(option.label)}
                </NavItem>
              </Link>
            ))
          }
          <Lang>
            <LanguagesDropdown activePageMeta={activeSubPageMeta}/>
          </Lang>
        </Nav>
      </Header>
      <div className="pt-12">
        {children}
      </div>
    </Container>)
}

export default Layout
