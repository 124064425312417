import React from "react"
import { Helmet } from "react-helmet"
import { RichText } from "prismic-reactjs"

const getGeneralCardMeta = (og) => {
  return og ? [
    {
      property: `og:title`,
      content: RichText.asText(og.title),
    },
    {
      property: `og:description`,
      content: RichText.asText(og.description),
    },
    {
      property: `og:image`,
      content: og.image.url,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:site_name`,
      content: `Tropical Building Physics`,
    },
  ] : []
}

const getTwitterCardMeta = (twitter) => {
  return twitter ? [
    {
      name: `twitter:card`,
      content: twitter.card_type,
    },
    {
      name: `twitter:creator`,
      content: twitter.twitter_handle,
    },
    {
      name: `twitter:title`,
      content: RichText.asText(twitter.title),
    },
    {
      name: `twitter:description`,
      content: RichText.asText(twitter.description),
    },
  ] : []
}


const socialMeta = {
  general_card: getGeneralCardMeta,
  twitter_card: getTwitterCardMeta,
}

const SEO = ({ description, lang, social, title }) => {
  let defaultMeta = [{
      name: "description",
      content: description,
    },]
  let meta = []
  if (social) {
    social.forEach((card, index) => {
      meta = [...meta, ...socialMeta[card.type](card.primary)]
    })
  }
  meta = [...meta, ...defaultMeta]
  return (
    <Helmet
      htmlAttributes={{
        lang: lang.split("-")[0],
      }}
      title={title}
      titleTemplate="%s"
      meta={meta}
    />
  )
}

export default SEO