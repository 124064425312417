import React, { useState } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import { linkResolver } from "@prismicio/gatsby-source-prismic-graphql"

const LangSelector = tw.div`
  m-auto float-right rounded
  text-base text-white 
  md:text-xs
`
const CurrentLang = tw.button`
  p-1 mb-1 block border-none rounded
  focus:outline-none font-bold
  bg-blue-600 hover:bg-blue-800
`
const AvailableLangs = tw.div`absolute block rounded`

const getBaseLang = (lang) => lang.toUpperCase().split("-")[0]

export const LanguagesDropdown = ({ activePageMeta }) => {
  const [isExpanded, toggleExpansion] = useState(false)
  const alternateLanguages = activePageMeta.alternateLanguages
  return (
    <LangSelector>
      <CurrentLang onClick={() => toggleExpansion(!isExpanded)}>{getBaseLang(activePageMeta.lang)}</CurrentLang>
      {
        isExpanded && Array.isArray(alternateLanguages) && alternateLanguages.length && (
          <AvailableLangs>
            {
              alternateLanguages.map((altLang, index) => (
                <Link tw="block p-1 mb-1 whitespace-no-wrap rounded
                          bg-blue-600 hover:bg-blue-800 font-bold"
                      to={linkResolver(altLang)}
                      key={index}>{getBaseLang(altLang.lang)}</Link>))
            }
          </AvailableLangs>
        )
      }
    </LangSelector>
  )
}